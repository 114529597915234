import styled from "styled-components"

const Column = styled.div`
    display:flex;
    flex-direction:column;
`;

const Row = styled.div`
    display:flex;
    flex-direction:row;
`;

const Overlay = styled.div`
    visibility: ${props=>props.visibility==="hidden"?"hidden":"visible"};
    pointer-events: all;
    z-index: 99999;
    border: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    max-width: none;
    cursor: wait;
    position: fixed;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color: rgba(0, 0, 0, 0.7);
`;



export {Column,Row, Overlay}