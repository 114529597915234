import  styled from "styled-components"

const TextArea = styled.textarea`
    width:100%;
    min-height:300px;
    max-width: 900px;
    border-radius: 5px;
`;

const Button = styled.button`
    padding: 0.5rem 1rem;
    background-color: #c709b7;
    color: white;
    font-weight: bold;
    border: 0;
    border-radius: 3px;

`;

export  {TextArea, Button}