import React, { Component } from 'react'
import { Flipper, Flipped } from 'react-flip-toolkit'
import Card from './components/Card'
import './styles/index.css'

import {TextArea, Button} from './styled/Input'
import shuffle from 'lodash/shuffle'

import {Overlay} from './styled/Layout'
import {WinnerName} from './styled/Typography'
import { ScalingSquaresSpinner } from 'react-epic-spinners'



class App extends Component {
  state = {
    type: 'grid',
    sort: 'asc',
    filteredIds: [],
    stagger: 'forward',
    spring: 'gentle',
    count:4,
    data: [],
    loading:"hidden",
    winner:{},
    inputText:"",
    datetime:""
  }

  addToFilteredIds = id => {
    this.setState(prevState => {
      return {
        filteredIds: prevState.filteredIds.concat(id)
      }
    })
  
  }

  setCount = (count,callback)=>{
    this.setState({count},()=>{
      callback();
    })
  }

  shuffleData = (val) =>{
    this.setCount(val,()=>{

      if(this.state.count>0){
        this.setState(prevState=>{
            return {
              data: shuffle(prevState.data)
            }
        },()=>{
            const length = this.state.data.length
            setTimeout(()=>{
              this.shuffleData(this.state.count-1);
            },1000+(100*length))
        

        })
      }else{
        this.setState({
          loading:"hidden",
          datetime: new Date(),
          winner:this.state.data.length >0?this.state.data[0]:{}
      })
      }
    })
   
  }

  inputData = (e) =>{
    let text = e.target.value
    let splittedText = text.split(/\r\n|\n|\r/)

    let newSplittedText = splittedText.map((item,index)=>
      item && {id:index,title:item}
    ).filter(item=>item && true||false)
    console.log(newSplittedText)
    this.setState({data:newSplittedText,inputText:text})
  }

  render() {

    const {data,loading,winner,inputText,datetime} = this.state
    console.log()
    return (
      <div className="fm-example">
        <Overlay visibility={loading}>
          <ScalingSquaresSpinner color="white"/>
        </Overlay>
        <div className="fm-description">
          <h1>Aplikasi Kocok Arisan </h1>
        </div>
        <Flipper

          flipKey={`${this.state.type}-${this.state.sort}-${JSON.stringify(
            this.state.filteredIds
          )}-${JSON.stringify(this.state.stagger)}-${data.reduce((e,{id})=>e+id,"")}`}

          // flipKey={data.reduce((e,title)=>e+title,"")}

          spring={this.state.spring}
          staggerConfig={{
            default: {
              reverse: this.state.stagger !== 'forward',
              speed: 0
            }
          }}
          decisionData={this.state}
        >
    

          <div>
            <TextArea placeholder="Daftar anggota dipisah dengan garis baru atau enter " onChange={this.inputData} value={inputText} /><br/>
            <Button onClick={()=>{this.setState({winner:{},data:[],inputText:"",datetime:""})}}>Reset</Button>&nbsp;
            <Button onClick={()=>{this.setState({loading:"visible"},()=>this.shuffleData(5));}}>Kocok</Button>&nbsp;
            {!!this.state.filteredIds.length && (
              <button
                className="fm-show-all"
                onClick={() => {
                  this.setState({
                    filteredIds: []
                  })
                }}
              >
                Tampilkan Semua
              </button>
            )}
          </div>
         
         <div>
           {
             winner && winner.title && 
             <div>
               <h2>Selamat kepada pemenang, <WinnerName>{winner.title}</WinnerName>!!</h2>
               <h4>pada pukul {datetime.getHours()}:{datetime.getMinutes()} tanggal {datetime.getDate()}-{datetime.getMonth()+1}-{datetime.getFullYear()}</h4>
             </div>

          }
         </div>

          <Flipped flipId="list">
            <div className={this.state.type === 'grid' ? 'fm-grid' : 'fm-list'}>
              <Flipped inverseFlipId="list">
                <ul className="list-contents">
                  {[...data]
                    .filter(d => !this.state.filteredIds.includes(d.id))
                    // .sort((a, b) => {
                    //   if (this.state.sort === 'asc') {
                    //     return a.id - b.id
                    //   } else {
                    //     return b.id - a.id
                    //   }
                    // })
                    .map(({ title, id },index) => (
                      <Card
                        id={id}
                        index={index}
                        title={title}
                        // stagger={['forward', 'reverse'].includes(
                        //   this.state.stagger
                        // )}
                        // type={this.state.type}
                        stagger={['forward', 'reverse'].includes(
                          this.state.stagger
                        )}
                        type={this.state.type}

                        key={id}
                        addToFilteredIds={this.addToFilteredIds}
                      />
                    ))}
                </ul>
              </Flipped>
            </div>
          </Flipped>
        </Flipper>
      </div>
    )
  }
}

export default App;
